var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('app-bar-nav-icon'),_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-message-text-fast")]),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Booking Requests ("+_vm._s(_vm.totalBookings)+")")]),_c('v-spacer')],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mx-4",attrs:{"id":"booking-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('div',{staticClass:"appbar mt-7 mb-3"},[_c('v-tabs',[_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'pending'
            _vm.activeAction()}}},[_vm._v(" Pending ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'accepted'
            _vm.activeAction()}}},[_vm._v(" Accepted ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'done'
            _vm.activeAction()}}},[_vm._v(" Done ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'declined'
            _vm.activeAction()}}},[_vm._v(" Declined ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'cancelled'
            _vm.activeAction()}}},[_vm._v(" Cancelled ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = null
            _vm.activeAction()}}},[_vm._v(" All ")])],1),_c('v-spacer'),_c('v-text-field',{ref:"searchField",staticClass:"appbar__filter",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search user, service and building"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"ml-10",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Bulk Actions ")])]}}])},[_c('v-list',_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.bookings,"server-items-length":_vm.totalBookings,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [25, 50, 100, -1],
      }},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch],"click:row":_vm.editItem},scopedSlots:_vm._u([{key:"item.user",fn:function({ item }){return [_vm._v(" "+_vm._s(item.userName)+" ")]}},{key:"item.bookable",fn:function({ item }){return [_vm._v(" "+_vm._s(item.serviceTitle)+" ")]}},{key:"item.type",fn:function({ item }){return [_vm._v(" "+_vm._s(item.serviceType)+" ")]}},{key:"item.datetime_start",fn:function({ item }){return [_vm._v(" "+_vm._s(item.bookingDate)+" ")]}},{key:"item.property",fn:function({ item }){return [_vm._v(" "+_vm._s(item.user?.property?.name)+" ")]}},{key:"item.building",fn:function({ item }){return [_vm._v(" "+_vm._s(item.buildingName)+" ")]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(item.addedDate)+" ")]}},{key:"item.status",fn:function({ item }){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('Status',{attrs:{"status":item.statusLabel}})],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(!(_vm.bookings.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }